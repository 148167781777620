<template>
  <UiPopup
    :model-value="modelValue"
    title="Add a note to the Lead timeline"
    description="Write a note and it will be saved in the Lead timeline"
    primary-button-text="Save"
    :loading="loading"
    secondary-button-text="Cancel"
    size="small"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="submit"
  >
    <UiInputTextareaNote
      v-model="note"
      name="note"
      placeholder="Your note..."
      class="mb-8 bg-white"
      :show-save-button="false"
    />
  </UiPopup>
</template>

<script setup lang="ts">
import { useUiStore } from '~/store/ui'
import type { TimelineItem } from '@/types'

const uiStore = useUiStore()

const emits = defineEmits(['update:modelValue'])

type Props = {
  modelValue: boolean
  leadId: number
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const loading = ref(false)

const note = ref('')

const submit = async () => {
  if (!note.value.length) return
  const activityItem = {
    type: 'note',
    comment: note.value,
    lead_id: props.leadId,
  } as unknown as Partial<TimelineItem>

  loading.value = true
  try {
    const response = await useCreateLeadActivity(activityItem)
    uiStore.showSnackBanner(response.message)
    emits('update:modelValue', false)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
}
</script>

<style scoped></style>
